import React from "react"

import { Layout, SEO } from "../components"
import "./agreement.css"
import "./main.css"

const Policy = () => {
  return (
    <Layout>
      <SEO
        title="Пользовательское соглашение приложения для онлайн проверки штрафов в Казахстане - OKauto"
        description="Подробная информация о пользовательском соглашении от разработчиков сервиса проверки штрафов ПДД в Казахстане - ТОО «OKauto». Лицензионное соглашение сервиса"
      />
      <div className="agreement__wrapper lh-copy animation">
        <h1 className="f1-l f2-m f3">Пользовательское соглашение</h1>
        <p>
          Настоящее Пользовательское соглашение (именуемое в дальнейшем "ПС")
          регулирует отношения по использованию мобильного приложения OKauto и
          WEB-сайта в сети Интернет (именуемой в дальнейшем "Сеть") с адресом
          http://www.okauto.kz (именуемом в дальнейшем "Okauto.kz") между ТОО
          «OKauto», владельцем сайта Okauto.kz, его администратором, и
          дееспособным лицом, надлежащим образом, присоединившимся к настоящему
          ПС (именуемым в дальнейшем "Пользователь"), предназначенного для
          получения сведений о неоплаченных штрафах за нарушение правил
          дорожного движения, утвержденных Постановлением Правительства
          Республики Казахстан от 13 ноября 2014 года № 1196 «Об утверждении
          Правил дорожного движения, Основных положений по допуску транспортных
          средств к эксплуатации, перечня оперативных и специальных служб,
          транспорт которых подлежит оборудованию специальными световыми и
          звуковыми сигналами и окраске по специальным цветографическим схемам.»
        </p>
        <br />
        <p>
          Изложенный ниже текст Пользовательского Соглашения является
          адресованным физическим и юридическим лицам официальным публичным
          предложением (офертой) ТОО «OKauto» заключить Соглашение в
          соответствии со ст. 395 Гражданского кодекса Республики Казахстан.
          Соглашение считается заключенным и приобретает силу с момента
          совершения физическим лицом действий, предусмотренных в настоящей
          Оферте и означающих полное и безоговорочное принятие физическим лицом
          всех условий настоящей Оферты без каких-либо изъятий или ограничений
          на условиях присоединения.
        </p>

        <h2 className="f2-l f3-m f4">1. Термины и определения</h2>

        <p>
          1.1. Сервис – веб-сайт okauto.kz, предназначенный для получения
          сведений о неоплаченных штрафах за нарушение ПДД и оплаты данных
          штрафов.
        </p>
        <p>
          1.2. Администрация – ТОО «OKauto», почтовый адрес: 050012,
          Республика Казахстан, г. Алматы, пр. Достык, д.132.
        </p>
        <p>
          1.3. Пользователь – физическое лицо и/или представитель юридического
          лица имеющее соответствующие полномочия, посетившее веб-сайт okauto.kz
          или использующее мобильное приложение.
        </p>
        <p>
          1.4. Штраф – административное наказание в виде штрафа за нарушение
          правил дорожного движения, утвержденных Постановлением Правительства
          Республики Казахстан от 13 ноября 2014 года № 1196 Об утверждении
          Правил дорожного движения, Основных положений по допуску транспортных
          средств к эксплуатации, перечня оперативных и специальных служб,
          транспорт которых подлежит оборудованию специальными световыми и
          звуковыми сигналами и окраске по специальным цветографическим схемам.
        </p>
        <p>
          1.6. Пользовательские данные – номер телефона Пользователя, адрес
          электронной почты Пользователя, Сведения о транспортном средстве,
          Индивидуальный идентификационный номер.
        </p>
        <p>
          1.7. Сведения о транспортном средстве – данные о государственном
          регистрационном номере транспортного средства Пользователя, номере
          свидетельства о регистрации транспортного средства Пользователя, и при
          необходимости, номере водительского удостоверения Пользователя.
        </p>
        <p>
          1.8. Персональные данные – информация, относящаяся к определенному или
          определяемому на основании такой информации физическому лицу.
        </p>
        <p>
          1.9. Обработка Персональных данных – действия (операции) с
          персональными данными, включая сбор, систематизацию, накопление,
          хранение, уточнение (обновление, изменение), использование,
          распространение (в том числе передачу), обезличивание, блокирование,
          уничтожение персональных данных.
        </p>
        <p>
          1.10. Интернет-эквайринг – способ оплаты через публичную сеть
          Интернет, посредством банковских карт.
        </p>
        <p>
          1.11. Банк- эквайрер – АО ДБ «Альфа Банк», который реализует и
          эксплуатирует платежный шлюз.
        </p>
        <p>
          1.12. Материалы веб-сайта – все текстовые, графические материалы,
          размещенные на Сервисе и являющиеся объектами интеллектуальной
          собственности, а также оформление и расположение указанных материалов
          (дизайн).
        </p>
        <p>
          1.13. Приложение OKauto – программная оболочка для оплаты
          административных штрафов разработанной для мобильных устройств,
          работающих на базе операционных систем «iOS» и «Android» , в том числе
          планшетных компьютеров (далее – мобильные устройства).
        </p>
        <p>
          1.14. PUSH уведомление это сообщение, которое появляются поверх окон
          запущенных программ на мобильном устройстве.
        </p>

        <h2 className="f2-l f3-m f4">
          2. Предмет Пользовательского Соглашения
        </h2>

        <p>
          2.1. Okauto.kz предлагает Пользователю свой сервис на условиях,
          являющихся предметом настоящего ПС.
        </p>
        <p>
          2.2. Настоящее ПС является открытым и общедоступным документом.
          Действующая редакция Пользовательского соглашения располагается в сети
          Интернет по адресу: http://okauto.kz/oferta.html
        </p>
        <p>
          2.3. Действия Пользователя по отправке запроса на получение информации
          о неоплаченных штрафах за нарушение ПДД с помощью специальной формы на
          Сервисе или установка Приложения в мобильное устройство Пользователя
          является акцептом оферты и подтверждением согласия Пользователя с
          условиями настоящего ПС.
        </p>
        <p>
          2.3. Принимая условия настоящего ПС, Пользователь подтверждает свое
          согласие на обработку Администрацией его данных, в том числе
          персональных, внесенных Пользователем в Приложение.
        </p>
        <p>
          2.4. Администрация предоставляет Пользователю права доступа и
          использования Сервиса и/или Приложения на условиях настоящего ПС.
        </p>
        <p>
          2.5. Настоящее ПС может быть изменено и/или дополнено Администрацией в
          одностороннем порядке, в любое время без какого-либо специального
          уведомления. При этом продолжение использования Сервиса и/или
          Приложения после внесения изменений и/или дополнений в настоящее ПС,
          означает согласие Пользователя с такими изменениями и/или
          дополнениями, в связи, с чем Пользователь обязуется регулярно
          отслеживать изменения в ПС, размещенном на сайте
          http://okauto.kz/oferta.html
        </p>

        <h2 className="f2-l f3-m f4">3. Описание услуг</h2>

        <p>
          3.1. Оказание информационно-технологических услуг посредством Сервиса
          и/или с использованием Приложения в режиме удаленного доступа через
          Интернет Пользователю по предоставлению сведений о неоплаченных
          Штрафах, по рассылке уведомлений о новых неоплаченных Штрафах
          посредством PUSH уведомлений и/или на e-mail Пользователя (далее –
          услуга уведомления), а также выполнение за вознаграждение
          Администрацией от имени и за счет Пользователя оплаты Штрафов
          Пользователя (далее – Поручение).
        </p>
        <p>
          3.2. Для получения сведений о неоплаченных Штрафах Пользователь вводит
          Сведения о транспортном средстве в форму на главной странице Сервиса
          или форму в Приложении и отправляет запрос путем нажатия клавиши
          «Проверить штрафы».
        </p>

        <h2 className="f2-l f3-m f4">4. Порядок Выполнения Поручения</h2>

        <p>
          4.1. После получения сведений о неоплаченных Штрафах, Пользователь
          имеет возможность оформить Поручение путем выбора неоплаченного Штрафа
          и нажатия клавиши «Оплатить».
        </p>
        <p>
          4.2. Вознаграждение Администрации за выполнение Поручения определяется
          в виде комиссии, измеряемой в зависимости от суммы Штрафа (без НДС), и
          указанной на странице оплаты Штрафа на Сервисе до осуществления
          оплаты. Администрация вправе в одностороннем порядке изменять размер
          вознаграждения без предварительного уведомления Пользователя. Размеры
          вознаграждения размещаются по следующему адресу
          http://okauto.kz/faq.html
        </p>
        <p>
          4.3. Пользователь осуществляет перевод денежных средств в счет оплаты
          Штрафа и вознаграждения Администрации с помощью электронных средств
          платежа в следующем порядке: после оформления Поручения Пользователь
          указывает в форме, представленной на Сервисе одним из партнеров
          Администрации, реквизиты банковской платежной карты или иного средства
          платежа, и производит перевод денежных средств путем нажатия
          соответствующей клавиши веб-интерфейса системы оплаты или
          соответствующей кнопки в Приложении.
        </p>
        <p>
          4.4. Услуги по обработке и авторизации платежей по банковским
          платежным картам и с помощью иных платежных систем для Сервиса
          предоставляются Банком-эвайрером.
        </p>
        <p>
          4.5. В зависимости от работоспособности указанных в п. 4.3, п. 4.4 ПС
          платежных систем и иных причин Сервис может использовать иные
          платежные системы без предварительного уведомления Пользователя. В
          этом случае соответствующие положения ПС будут относиться к данной
          платежной системе.
        </p>
        <h2 className="f2-l f3-m f4">
          5. Конфиденциальность и персональные данные
        </h2>

        <p>
          5.1. Для целей оплаты Штрафа Пользователь предоставляет и дает
          согласие на обработку следующих Персональных данных: фамилию, имя и
          отчество Пользователя, номера Индивидуального идентификационного
          номера и свидетельства о регистрации транспортного средства.
        </p>
        <p>
          5.2. Персональные данные Пользователей хранятся исключительно на
          электронных носителях и обрабатываются с использованием
          автоматизированных систем, за исключением случаев, когда
          неавтоматизированная обработка Персональных данных необходима в связи
          с исполнением требований законодательства РК.
        </p>
        <p>
          5.3. Хранение Персональных данных осуществляется в форме, позволяющей
          определить субъекта Персональных данных не дольше, чем этого требуют
          цели Обработки Персональных данных, если срок хранения Персональных
          данных не установлен законодательством РК, договором, стороной
          которого, выгодоприобретателем или поручителем, по которому является
          субъект Персональных данных. Обрабатываемые персональные данные
          подлежат уничтожению, либо обезличиванию по достижении целей обработки
          или в случае утраты необходимости в достижении этих целей, если иное
          не предусмотрено законодательством РК.
        </p>
        <p>
          5.4. При оплате Штрафов реквизиты банковской карты, а также фамилия,
          имя держателя банковской карты вводятся Пользователем в онлайн-форму,
          предоставленную Сервису партнером Администрации – Банком-эвайрером. В
          этом случае данные отправляются непосредственно Банку-эквайреру и не
          обрабатываются Сервисом.
        </p>
        <p>
          5.5. Доступ к Пользовательским данным предоставляется Администрацией
          только лицам, имеющим непосредственное отношение к оплате Штрафов, а
          также в случае запроса таких данных уполномоченными законодательством
          Республики Казахстан органами.
        </p>
        <p>
          5.6. Администрация Сервиса обязуется соблюдать конфиденциальность в
          отношении Пользовательских данных, не допускать несанкционированного
          использования Пользовательских данных третьими лицами, исключить
          доступ к Пользовательским данным лиц, не имеющих непосредственного
          отношения к получению сведений о неоплаченных Штрафах и оплате Штрафов
          Пользователя.
        </p>
        <p>
          5.7. В случае использования Пользователем данных для получения
          сведений о неоплаченных Штрафах либо оплаты Штрафов третьего лица,
          Пользователь гарантирует, что обладает полномочиями на использование
          таких данных и получение соответствующих сведений, и несет
          самостоятельную ответственность при отсутствии полномочий на
          использование Пользовательских данных.
        </p>
        <p>
          5.8. Приложение собирает и хранит только те персональные данные
          Пользователя, которые необходимы для оказания услуг.
        </p>
        <p>
          5.9. Персональная информация Пользователя в Приложении может быть
          использована в следующих целях: Идентификации стороны в рамках
          заключенных соглашений и договоров; Предоставления Пользователю
          персонализированных услуг; Связи с Пользователем, в том числе
          направление уведомлений, запросов, предложений и информации,
          касающихся использования Приложения, оказания услуг, а также обработки
          запросов и заявок от Пользователя; Проведения статистических и иных
          исследований, на основе обезличенных данных.
        </p>
        <p>
          5.10. В рамках настоящего ПС под «персональными данными Пользователя
          понимаются: Персональные данные, которые Пользователь предоставляет о
          себе самостоятельно или в процессе использования Приложения, либо
          которые помечены специальным образом и обязательны для предоставления
          Сервисов (оказания услуг). Иные данные предоставляются Пользователем
          на его усмотрение; Данные, которые автоматически передаются сервисам
          Приложения в процессе их использования, с помощью установленного на
          устройстве Пользователя программного обеспечения, в том числе
          IP­адрес, информация, идентифицирующая устройство и оставленная в
          мобильных устройствах Пользователя при предыдущих сессиях
          обслуживания, информация (или иной программе, с помощью которой
          осуществляется доступ к Сервисам), адрес запрашиваемой страницы.
        </p>

        <h2 className="f2-l f3-m f4">
          6. Интеллектуальная собственность и авторские права
        </h2>

        <p>
          6.1. Исключительные права на Материалы веб-сайта принадлежат
          Администрации либо их правообладателям.
        </p>
        <p>
          6.2. Копирование Материалов веб-сайта без письменного разрешения
          Администрации или правообладателей влечет ответственность в
          соответствии с законодательством Республики Казахстан.
        </p>
        <p>
          6.3. Не допускается получение Пользователем либо третьими лицами или
          передача Пользователем третьим лицам информации о неоплаченных Штрафах
          с помощью Сервиса и/или Приложения с использованием автоматических
          программных и технических средств (парсинг), в том числе с целью
          предоставления такой информации на каких-либо иных веб-сайтах помимо
          Сервиса, а также в каком-либо ином программном обеспечении, включая
          мобильные приложения, кроме случаев использования официального виджета
          Сервиса.
        </p>

        <h2 className="f2-l f3-m f4">7. Ограничение ответственности </h2>

        <p>
          7.1. Поручение по оплате Штрафов и оказание услуг выполняются
          Администрацией в любое время, однако Администрация не гарантирует
          отсутствие технических перерывов в работе Сервиса. Перерывы в
          предоставлении услуг и выполнении поручения обуславливаются действием
          или бездействием третьих лиц и/или неработоспособностью информационных
          каналов, находящихся за пределами собственных ресурсов Администрации,
          а также необходимым профилактическим ремонтом и обслуживанием
          оборудования Администрации, в том числе по аварийным обстоятельствам.
        </p>
        <p>
          7.2. Администрация не несет ответственности перед Пользователем за
          задержки, перебои в работе и невозможность полноценного использования
          собственных ресурсов Администрации, происходящие прямо или косвенно по
          причине действия или бездействия третьих лиц и/или
          неработоспособностью информационных каналов, находящихся за пределами
          собственных ресурсов Администрации.
        </p>
        <p>
          7.3. Администрация не несет ответственности за неполученную прибыль и
          упущенную выгоду, а также за любые косвенные убытки, понесенные
          Пользователем в период использования или не использования Сервиса.
        </p>
        <p>
          7.4. Администрация не несет ответственности за ущерб, прямой и
          косвенный, полученный вследствие получения доступа со стороны третьих
          лиц мобильному устройству или на странице Сервиса.
        </p>
        <p>
          7.5. Администрация не несет ответственности за изменение свойств,
          функций и способов выполнения поручения по оплате Штрафов, если
          таковые явно не описаны в Договоре.
        </p>
        <p>
          7.6. Администрация не несет ответственности за качество,
          безошибочность и наличие вредоносных компонентов в используемом на
          компьютере и серверах Сервиса, Администрации и других серверах Сети
          Интернет или предлагаемом Пользователю программном обеспечении, если
          таковое не разработано Администрацией.
        </p>
        <p>
          7.7. Администрация Сервиса не является лицом, уполномоченным на
          составление базы данных о неоплаченных Штрафах, не несет
          ответственность за достоверность и актуальность получаемых в
          автоматическом режиме сведений о наличии неоплаченных Штрафов. В
          случае, если Пользователь считает, что информация о Штрафах,
          предоставленная Сервисом не является достоверной, Пользователю
          надлежит обратиться в соответствующее территориальное Управление
          Местной полицейской службе МВД РК с целью уточнения информации о
          неоплаченных Штрафах.
        </p>
        <p>
          7.8. Сервис осуществляет получение информации о неоплаченных Штрафах с
          помощью доступных законных технических и информационных средств на
          основе соглашений с партнерами Сервиса либо непосредственно с органами
          Местной полицейской службы в зависимости от региона, в связи, с чем
          Администрация не несет ответственности перед Пользователями в случае
          задержек либо искажении в предоставленной информации о неоплаченных
          Штрафах, возникших по независящим от Администрации причинам.
        </p>
        <p>
          7.9. Пользователю запрещено самостоятельно или с привлечением третьих
          лиц осуществлять декомпилирование Приложения, а также осуществлять
          реверс-инжиниринг Приложения.
        </p>
        <p>
          7.10. Пользователь обязуется принимать надлежащие меры для обеспечения
          сохранности его мобильного устройства и несет личную ответственность в
          случае доступа к его мобильному устройству третьих лиц.
        </p>
        <p>
          7.11. Пользователь обязуется не использовать Приложение в нарушение
          прав и законных интересов Администрации, иных правообладателей,
          третьих лиц и настоящего ПС.
        </p>
        <p>
          7.12. Для исполнения ПС могут привлекаться третьи лица. Пользователь
          подтверждает, что указанным третьим лицам предоставляются такие же
          права, как и обладателю прав на использование Сервиса и Приложение, в
          том числе в отношении персональных данных Пользователя.
        </p>
        <p>
          7.13. Пользователь несет полную ответственность за несанкционированный
          доступ третьих лиц к Приложению, установленному на мобильное
          устройство Пользователя.
        </p>
        <p>
          7.14. В случае утери, удаления, повреждения внесенной Пользователем в
          Приложение информации в результате действий/бездействий Пользователя,
          всю ответственность несет Пользователь.
        </p>

        <h2 className="f2-l f3-m f4">8. Порядок рассмотрения претензий</h2>

        <p>
          8.1. Настоящее ПС заключено на неопределенный срок, действует на
          территории РК.
        </p>
        <p>
          8.2. К положениям настоящего ПС применяется законодательство
          Республики Казахстан. Вопросы, не урегулированные настоящим ПС,
          разрешаются в соответствии с законодательством Республики Казахстан.
        </p>
        <p>
          8.3. Все споры, возникающие в связи с использованием Сервиса,
          разрешаются Пользователем и Администрацией в досудебном порядке путем
          переговоров. Пользователь, считающий, что его права и интересы
          нарушены из-за действий Okauto.kz, посылает последнему по электронной
          почте support@okauto.kz претензию. В течение 5 (пяти) рабочих дней со
          дня получения претензии Okauto.kz в праве излагать или воздержаться
          излагать свою позицию по указанным в последней принципиальным вопросам
          и послать свой ответ по адресу электронной почты, указанному в
          претензии. При невозможности прийти к согласию в досудебном порядке
          споры между Пользователем и Администрацией подлежат рассмотрению в
          судебном органе Республики Казахстан по месту нахождения
          Администрации.
        </p>
        <p>
          8.4. Не рассматриваются анонимные претензии или претензии, не
          позволяющие идентифицировать пользователя на основе полученного
          письма.
        </p>

        <h2 className="f2-l f3-m f4">9. Изменения и дополнения к ПС</h2>

        <p>
          9.1. Изменения и дополнения к ПС вступают в силу через семь
          календарных дней после их опубликования на сайте www.okauto.kz или
          позже, при указании более поздней даты вступления в силу. Пользователь
          вправе отвергнуть дополнительные внесения либо изменения в настоящее
          ПС, производимые Администрацией, что означает полный и без каких-либо
          претензий отказ Пользователем от услуг Администрации.
        </p>

        <h2 className="f2-l f3-m f4">10. Заключительные положения</h2>

        <p>
          10.1. В случае возникновения любых споров или разногласий, связанных с
          исполнением ПС, Пользователь и Администрация приложат все усилия для
          их разрешения путем проведения переговоров между ними. В случае если
          споры не будут разрешены путем переговоров, споры подлежат разрешению
          в соответствующем компетентном суде по месту нахождения Администрации.
        </p>
        <p>
          10.2. Администрация имеет право производить профилактические работы в
          программно-аппаратном комплексе Сервиса и/или Приложения с временным
          приостановлением работы Сервиса/Приложения, не уведомляя об этом
          Пользователя за один день до начала работ и указанием сроков ее
          окончания.
        </p>
        <p>
          10.3. Ни одна из Сторон ПС не несет ответственности за неисполнение
          или ненадлежащее исполнение обязательств по-настоящему ПС, если
          надлежащее исполнение оказалось невозможным вследствие непреодолимой
          силы. В случае наступления форс-мажорных обстоятельств, а также аварий
          или сбоев в программно-аппаратных комплексах третьих лиц,
          сотрудничающих с Администрацией, или действий третьих лиц,
          направленных на приостановку или прекращение функционирования Сервиса
          и/или Приложения, возможна приостановка работы Сервиса и/или
          Приложения без предварительного уведомления Пользователя.
        </p>
        <p>
          10.4. ПС вступает в силу для Пользователя с момента загрузки
          Приложения в мобильное устройство или использования Сервиса.
        </p>
        <p>10.5. ПС составлено на русском языке.</p>
        <p>
          10.6. Если какое-либо из положений настоящего ПС будет признано
          недействительным, это не оказывает влияния на действительность или
          применимость остальных положений ПС.
        </p>
      </div>
    </Layout>
  )
}

export default Policy
